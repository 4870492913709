<template>
  <div style="overflow: hidden">
    <div class="foot width animate__animated animate__fadeInDown wow">
      <div class="form">
        <div class="container width">
          <div class="text">
            <div class="item">
              <span class="iconfont icon-dianhuatianchong"></span>
              <div>
                <p class="phone">400-878-6390</p>
                <a>咨询热线</a>
              </div>
            </div>
            <div class="item">
              <span class="iconfont icon-ico_site"></span>
              <div>
                <p>西安市莲湖区大庆路3号天朗蔚蓝国际A座1202室</p>
              </div>
            </div>
            <div class="item">
              <span class="iconfont icon-youxiang"></span>
              <div>
                <p>service@ginkgosoft.cn</p>
              </div>
            </div>
          </div>
          <div class="input">
            <div class="input-container">
              <div>
                <input type="text" placeholder="请输入姓名" />
                <input type="text" placeholder="请输入电话" />
              </div>
              <textarea name="" id="" placeholder="留言内容"></textarea>
            </div>
            <c-button content="提交留言" />
          </div>
        </div>
      </div>
      <div class="copyright">
        <div>
          版权归西安银杏软件有限公司所有，只能用于西安银杏软件有限公司，使用本品须西安银杏软件有限公司同意，违者必究。
        </div>
        <span @click="record">© copyright 陕ICP备14001946号-4</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    record() {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style lang="scss" scoped>
input,
textarea {
  border: none;
  outline: none;
  background: #16274c;
  color: #999;
  font-size: 16px;
  padding: 14px 20px;
  resize: none;
  border-radius: 3px;
}
.foot {
  width: 100%;
  height: 450px;
  overflow: hidden;
  .form {
    height: 85%;
    background: #000e2d;
    display: flex;
    align-items: center;
    .container {
      height: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        width: 45%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
          display: flex;
          align-items: center;
          .iconfont {
            color: #ff9e00;
            margin-right: 40px;
            font-size: 26px;
          }
          div {
            color: #fff;
            a {
              color: #9b9b9b;
              margin-top: 10px;
            }
          }
          .phone {
            font-size: 26px;
          }
        }
      }
      .input {
        width: 55%;
        height: 70%;
        .input-container {
          width: 100%;
          height: 126px;
          display: flex;
          justify-content: space-between;
          div,
          textarea {
            width: calc((100% - 40px) / 2);
          }
          div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            input {
              width: 100%;
              height: 48px;
            }
          }
        }
        #cbutton {
          margin-top: 30px;
        }
      }
    }
  }
  .copyright {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666666;
    background-color: #000a21;
    div {
      width: 100%;
      text-align: center;
    }
    span {
      transition: all 0.3s;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        color: red;
      }
    }
  }
}

@media all and (max-width: 1650px) {
  .foot {
    height: 400px !important;
  }
}
@media all and (max-width: 1550px) {
  .foot {
    height: 400px !important;
  }
}
@media all and (max-width: 1450px) {
  .foot {
    height: 370px !important;
    .form .container {
      .text {
        .item .iconfont {
          font-size: 24px !important;
          .phone {
            font-size: 24px !important;
          }
        }
      }
      .input {
        width: 50% !important;
      }
    }
  }
}
@media all and (max-width: 1350px) {
  .foot {
    height: 340px !important;
  }
}
@media all and (max-width: 1270px) {
}
@media all and (max-width: 1170px) {
}
</style>
